
import { computed, defineComponent, onMounted, ref } from "vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import FactorLegend from "@/components/diagnostic/FactorLegend.vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { Diagnostic } from "@/store/modules/DiagnosticsModule";
import { OTEX, SIQO } from "@/store/modules/ExploitationsModule";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { notify } from "@/core/helpers/globalJaya";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "ResultDataBackOffice",
  components: {
    CardTitleJaya,
    FactorLegend,
  },
  setup() {
    interface SeriesObject {
      name: string;
      data: number[];
    }

    interface DepartmentObject {
      id: number;
      name?: string;
      insee_dep?: number;
    }

    const store = useStore();
    const router = useRouter();

    let chartType = ref<string>("bar");
    let diagnostic = ref<Diagnostic>({
      id: -1,
      date_evaluated: new Date().toISOString(),
      is_real: false,
      finished: false,
      exploitation: -1,
      exploitation_id: -1,
      date_created: new Date().toISOString(),
      results: [],
    });
    let series = ref<SeriesObject[]>([
      {
        name: "Diagnostic",
        data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      },
    ]);
    let comparingDiagnosticsNumber = ref<number>(0);
    let globalScore = ref<number>(0);
    let editingOTEX = ref<OTEX>({ id: null });
    let editingDepartment = ref<DepartmentObject>({
      id: -1,
    });
    let editingGeoPoint = ref<boolean | null>(null); //todo: set real geopoint
    let editingSIQO = ref<SIQO>({ id: null });
    let displayChart = ref<boolean>(true);
    let departments = ref<Array<DepartmentObject>>([]);
    const getOTEX = computed(() => store.getters.getOTEX);
    const getSIQO = computed(() => store.getters.getSIQO);
    const factors = computed(() => store.getters.getFactors);
    const chartOptionsBar = computed(() => {
      return {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              orientation: "vertical",
              position: "top",
            },
          },
        },
        colors: [
          getCSSVariableValue("--bs-primary"),
          getCSSVariableValue("--bs-secondary"),
          getCSSVariableValue("--bs-dark"),
          getCSSVariableValue("--bs-warning"),
          getCSSVariableValue("--bs-danger"),
        ],
        dataLabels: {
          enabled: true,
          formatter: (value, { seriesIndex }) => {
            if (seriesIndex === 0) {
              return value - 1;
            }
            return (value - 1).toFixed(1);
          },
          offsetY: -23,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          lineCap: "butt",
          width: 0.5,
          colors: ["#fff"],
          opacity: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            formatter: (label) => {
              let modifiedLabel = "";
              if (factors.value.some((factor) => factor.number === label)) {
                modifiedLabel = factors.value.find(
                  (factor) => factor.number === label
                ).title;
              }
              return label + " : " + modifiedLabel;
            },
          },
          y: {
            formatter: (value) => {
              return "Niveau " + (value - 1).toString();
            },
          },
        },
        xaxis: {
          categories: factors.value.map((factor) => factor.number),
          inverseOrder: true,
        },
        yaxis: {
          position: "top",
          tickAmount: 6,
          min: 0,
          max: 6,
          labels: {
            show: false,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
        },
        fill: {
          opacity: 1,
        },
      };
    });
    const chartOptionsRadar = computed(() => {
      return {
        chart: {
          type: "radar",
        },
        plotOptions: {
          radar: {
            distributed: true,
          },
        },
        colors: [
          getCSSVariableValue("--bs-primary"),
          getCSSVariableValue("--bs-secondary"),
          getCSSVariableValue("--bs-dark"),
          getCSSVariableValue("--bs-warning"),
          getCSSVariableValue("--bs-danger"),
        ],
        dataLabels: {
          enabled: true,
          formatter: (value, { seriesIndex }) => {
            if (seriesIndex === 0) {
              return value - 1;
            }
            return (value - 1).toFixed(1);
          },
          style: {
            fontSize: "10px",
          },
        },
        stroke: {
          width: 2,
        },
        fill: {
          opacity: 0.1,
        },
        xaxis: {
          categories: factors.value.map((factor) => factor.number),
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (label) => {
              return label - 1;
            },
          },
        },
        legend: {
          position: "bottom",
        },
      };
    });

    const getDiagOTEX = () => {
      if (
        store.getters.getExploitations.some(
          (exploitation) => exploitation.id === diagnostic.value.exploitation_id
        )
      ) {
        const linkedExploitation = store.getters.getExploitations.find(
          (exploitation) => exploitation.id === diagnostic.value.exploitation_id
        );
        if (
          linkedExploitation.exploitation_versions[0].otex &&
          linkedExploitation.exploitation_versions[0].otex.id >= 0
        ) {
          let diagOTEX = getOTEX.value.find(
            (otex) =>
              otex.id === linkedExploitation.exploitation_versions[0].otex.id
          );
          editingOTEX.value.id = diagOTEX.id;
          editingOTEX.value.name = diagOTEX.name;
        }
      }
    };

    const setGlobalScore = () => {
      let finalResult = ref<number>(0);
      for (const result of diagnostic.value.results) {
        finalResult.value = finalResult.value + result.level_details.level;
      }
      globalScore.value = Math.round(
        (finalResult.value / (factors.value.length * 5)) * 100
      );
    };

    const setType = (type) => {
      displayChart.value = false;
      chartType.value = type;
      setTimeout(() => {
        displayChart.value = true;
      }, 10);
    };

    const setDiagResults = () => {
      let factorLevelsResult = ref<number[]>([]);
      for (const factor of factors.value) {
        let factorResult = diagnostic.value.results.find(
          (result) => result.level_details.factor === factor.id
        );

        if (factorResult) {
          factorLevelsResult.value.push(factorResult.level_details.level + 1);
        } else {
          factorLevelsResult.value.push(1);
        }
      }
      series.value[0].data = factorLevelsResult.value;
    };

    const setComparingSeries = (
      name: string,
      data = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
    ) => {
      if (series.value[1] && series.value[1].name) {
        series.value[1].name = name;
        series.value[1].data = data;
      } else {
        series.value.push({
          name: name,
          data: data,
        });
      }
    };

    const clearSelection = () => {
      editingOTEX.value = { id: -1 };
      editingGeoPoint.value = null;
      editingSIQO.value = { id: -1, name: "Choisissez un label" };
    };

    const getNewAverage = () => {
      let payload = ref<any>({});
      payload.value.otex =
        editingOTEX.value.id !== -1 ? [editingOTEX.value.id] : [];
      payload.value.siqo =
        editingSIQO.value.id !== -1 ? [editingSIQO.value.id] : [];
      payload.value.departments =
        editingDepartment.value.id !== -1 ? [editingDepartment.value.id] : [];

      store
        .dispatch(Actions.GET_DIAGNOSTIC_STATS_USER, payload.value)
        .then((response) => {
          let responseData = response[0]["stats"];
          comparingDiagnosticsNumber.value = responseData.count["F1-1"];
          // Need at least 10 answers to mke average
          if (comparingDiagnosticsNumber.value > 0) {
            let resultArray = [] as Array<number>;
            for (const fact of factors.value) {
              resultArray.push(1 + responseData.avg[fact.number]);
            }
            setComparingSeries("Référence", resultArray);
          } else {
            notify({
              text: "Pas assez de données dans cette catégorie. Veuillez séléctionner des données différentes",
              color: "error",
              duration: 6000,
            });
            series.value = [series.value[0]];
          }
        })
        .catch(() => {
          notify({
            text: "Erreur à la récupération des données",
            color: "warning",
            duration: 3000,
          });
          setComparingSeries("Aucune référence sélectionnée");
        });
    };
    const openSwal = () => {
      Swal.fire({
        title: "Références moyennes",
        html:
          "<p>Les moyennes sont calculées par agrégation des diagnostics réalisés et pour lesquels les " +
          "utilisateurs ont accepté le partage des données </p>",
        icon: "question",
        buttonsStyling: false,
        confirmButtonText: "Fermer",
        showCloseButton: true,
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      });
    };

    onMounted(() => {
      store
        .dispatch(Actions.GET_LOCATIONS, { type: "departments", limit: 150 })
        .then((response) => {
          departments.value = response.results;
        });
      store.dispatch(Actions.FETCH_EXPLOITATIONS).then(() => {
        store.dispatch(Actions.FETCH_OTEX).then(() => {
          store.dispatch(Actions.FETCH_QUIZ).then(() => {
            store.dispatch(Actions.FETCH_DIAGNOSTICS).then(() => {
              if (
                store.getters.getDiagnostics.some(
                  (diagnostic) =>
                    diagnostic.id ===
                    +router.currentRoute.value.params.diagnosticId
                )
              ) {
                diagnostic.value = store.getters.getDiagnostics.find(
                  (diagnostic) =>
                    diagnostic.id ===
                    +router.currentRoute.value.params.diagnosticId
                );
              }
              getDiagOTEX();
              setGlobalScore();
              setDiagResults();
              getNewAverage();
            });
          });
        });
      });
    });

    const displayFactorDetail = (event, chartContext, config) => {
      router.push({
        name: "edit-diagnostic",
        params: { diagnosticId: diagnostic.value.id },
        hash: "#" + factors.value[config.dataPointIndex].id.toString(),
      });
    };

    return {
      chartOptionsBar,
      chartOptionsRadar,
      displayChart,
      series,
      chartType,
      getOTEX,
      editingOTEX,
      editingGeoPoint,
      editingSIQO,
      getSIQO,
      clearSelection,
      getNewAverage,
      setType,
      openSwal,
      editingDepartment,
      departments,
      displayFactorDetail,
      factors,
    };
  },
});
